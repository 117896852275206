import React from "react";
import UnderConstruction from "../../components/UnderConstruction";

const Ebug = () => {
    return (
		  <UnderConstruction />
    );
};

export default Ebug;
